import React from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Button from '@components/button';

const SiteSearch = () => {
  const router = useRouter();
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');

  const clickSearch = e => {
    e?.preventDefault();
    if (query.length > 0) {
      router.push(
        `${process.env.API_BASEURL}/site-search?q=${query.toLowerCase()}`
      );
      setQuery('');
    } else {
      setError('Error: Invalid input');
    }
  };
  return (
    <>
      <form
        className='pt-5 landscape:sm:w-[38%] landscape:md:w-4/6 portrait:sm:w-3/4 portrait:lg:w-2/3 landscape:lg:w-[70%] xl:w-1/2'
        data-testid='search-input'
      >
        <label
          htmlFor='default-search'
          className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
        >
          Search
        </label>
        <div className='relative'>
          <div className='absolute inset-y-0 left-0 flex top-[17%] sm:items-center sm:top-[0%]  pl-3 pointer-events-none'>
            <svg
              aria-hidden='true'
              className='w-5 h-5 text-gray-500 dark:text-gray-400'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
              />
            </svg>
          </div>
          <input
            type='text'
            id='default-search'
            className='block w-full p-4 pl-10 lg:text-3xl text-lg text-white border-b bg-transparent  font-proLight focus:outline-none'
            placeholder='Search Home Energy'
            onChange={e => setQuery(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') clickSearch();
            }}
            value={query}
          />
          <Button
            type='submit'
            className='text-white sm:absolute w-full sm:w-auto p-0 mt-5 sm:mt-0 right-2.5 bottom-2.5 bg-transparent font-ballingerMono border focus:ring-4 focus:outline-none focus:!bg-transparent hover:!bg-transparent font-medium text-xs px-3 py-2 '
            onClick={e => clickSearch(e)}
            onKeyDown={e => {
              if (e.key === 'Enter') clickSearch();
            }}
          >
            Search
          </Button>
        </div>
        <div className='text-red-600'>{error}</div>
      </form>
    </>
  );
};
export default SiteSearch;
